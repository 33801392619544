import { graphql, useStaticQuery } from 'gatsby'
import { useEffect, useState } from 'react'

const useBundle = slug => {
  const data = useStaticQuery(
    graphql`
      query bundleColletion {
        allContentfulBundle {
          bundles: nodes {
            name
            slug
            masterSku
            redirectSlug
            bundleSize
            omsTag
            mainImage {
              title
              gatsbyImageData
            }
          }
        }
      }
    `,
  )

  const { bundles } = data.allContentfulBundle

  const [cmsBundle, setCmsBundle] = useState(null)

  useEffect(() => {
    const bundle = bundles?.find(p => p.slug === slug)

    if (!bundle) return

    setCmsBundle(bundle)
  }, [bundles, slug])

  return cmsBundle
}

export default useBundle
